<template>
  <div>
    <div class="contents">

     <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="70px">
           <el-form-item label="日期" style="margin-bottom:10px">
            <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
            <el-form-item label="类目" style="margin-bottom:10px">
              <el-select v-model="searchForm.category" clearable  placeholder="全部" style="width:120px">
                <el-option
                  v-for="item in categoryArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="城市" style="margin-bottom:10px">
              <el-cascader v-model="searchForm.city" filterable :options="provinceArr" :props="props" clearable :show-all-levels="false"></el-cascader>
               <!-- <el-select v-model="searchForm.province" placeholder="选择省" @change="checkProvince">
                <el-option
                  v-for="item in provinceArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              <el-select v-model="searchForm.city" placeholder="选择市">
                <el-option
                  v-for="item in cityArr"
                  :key="item.adcode"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="供应商" style="margin-bottom:0px">
               <el-input v-model="searchForm.supplierName" clearable placeholder="供应商名称"></el-input>
            </el-form-item>
            <el-form-item label="" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable placeholder="团购标题" style="width:220px"></el-input>
            </el-form-item>
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="chongzhi" >重置</el-button>
          </el-form>
        </div>
      <div class="cardList"> 
        <div class="cardBox" v-for="item in tableData" v:key="item.id">
          <div class="cardItem">
            <el-tooltip placement="top">
            <div v-html="item.name" class="titlebox" slot="content"></div>
          <div class="biaoti">
            <template v-if="item.cities.length > 0">
            <template v-for="items in item.cityArr" v:key="items">
            <bdo v-if="items.city">{{items.city}}</bdo>
            </template>
            </template>
            <bdo v-else>全国</bdo>
            {{item.name}}</div>
              </el-tooltip>
          <div class="pricebox">
            <span>{{item.beginTimes}}至{{item.endTimes}}</span><em>￥</em>{{item.minPrice?item.minPrice:0}}
          </div>
          <div class="imglist">
            <template v-for="(it, indexs) in item.images" v:key="indexs">
              <ol><img :src="it" class="imgsize"/></ol>
            </template>
          </div>
          <div class="clickNumIndex">
            <div class="itemindex">
              <div class="itemStrong">{{item.browseTotal?item.browseTotal:0}}</div>
              <bdo>浏览数</bdo>
            </div>
            <div class="itemindex">
              <div class="itemStrong">{{item.favoriteTotal?item.favoriteTotal:0}}</div>
              <bdo>分销数</bdo>
           </div>
            <div class="itemindex">
              <bdo style="margin-top:10px; display: block;text-align:right;cursor: pointer;" @click="chakantuan(item)">团购详情<i class="iconfont icon-iconfontjiantou2"></i></bdo>
            </div>
          </div>
          <div class="caozuoBtn">
           <bdo v-if="item.status ==1">{{item.statusDescription}}</bdo>
           <bdo v-else class="huise">{{item.statusDescription}}</bdo>
              <span><el-button type="primary" @click.native.prevent="choucang(item)">我要分销</el-button></span>
              <!-- <span><el-button v-if="item.status ==1 && !item.favorite" @click.native.prevent="cancalBtn(item.id)">取消分销</el-button></span> -->
              <!-- <span><el-button @click.native.prevent="uploadFun(item.id)">上传订单</el-button></span> -->
          </div>
          </div>
        </div>
      </div>
     

     <div class="paginations">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[18, 36, 54, 72]"
          :total="total">
        </el-pagination>
      </div>
    </div>


       <el-dialog title="我要分销" :visible.sync="downflag" :before-close="downClose" width="660px">
        <div class="fenxiaoBox">
           <template v-for="(item, index) in barImagesArr">
             <ol>
            <el-image :src="item.resourceCode?item.resourceCode:jingqing" class="fenimg" :preview-src-list="srcList"></el-image>
            <p>{{item.value}}</p>
            <el-button type="primary" :disabled="item.resourceCode?false:true" @click.native.prevent="downloadFun(item.resourceCode)">下载素材</el-button>
            </ol>
           </template>  
        </div>
      </el-dialog>

     <el-dialog title="上传订单" :visible.sync="uploadflag" :before-close="handleClose" width="600px">
      <el-form ref="formAdd" :model="formAdd" label-position="right">
        <el-upload
          class="upload-demo"
          drag
          action="string"
          :http-request="UploadImage"
          :on-progress="jinduFun"
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传excel文件，且不超过10M</div>
        </el-upload>
      </el-form>
    </el-dialog>

    <el-dialog title="订单解析结果" :visible.sync="orderflag" :before-close="ooClose" width="1000px" style="margin-top:5%">
      <div class="orderHeight">
      <el-table
        :data="orderData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          prop="onlineOrderId"
          label="订单号">
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品">
        </el-table-column>
        <el-table-column
          prop="specName"
          label="规格">
        </el-table-column>
        <el-table-column
          prop="num"
          label="数量">
        </el-table-column>
        <el-table-column
          prop="consigneeName"
          label="收货人" width="100px"> 
        </el-table-column>
        <el-table-column
          prop="consigneePhone"
          label="电话" width="120px"> 
        </el-table-column>
        <el-table-column
          prop="consigneeAddress"
          label="收货地址">
        </el-table-column>
         <!-- <el-table-column
          label="操作"
          width="100" header-align="center" align="center">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="modifyFun(scope.row)">修改</el-button>
      </template>
    </el-table-column> -->
      </el-table>
      </div>
        <div class="midCss" style="padding-top:20px">
          <el-button type="primary" @click="handleSubmit">确认保存</el-button>
          <el-button @click="handleClose">取 消</el-button>
        </div>
    </el-dialog>

    <el-dialog title="订单修改" :visible.sync="modyflag" :before-close="modyClose" width="600px">
      <el-form ref="formAdd" :model="formAdd" label-position="right">
       <el-form-item label="商品名称：">
            <el-input v-model="formAdd.goodsName" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="收货人：">
            <el-input v-model="formAdd.consigneeName" placeholder="请输入收货人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input v-model="formAdd.consigneePhone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="联系地址：">
            <el-input v-model="formAdd.consigneeAddress" placeholder="请输入联系地址"></el-input>
          </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { dashboardTuan, favoriteTuan, cancelFavorite } from "@/api/kaituan";
import { importOrder, batchAdd } from "@/api/orders";
import { globalCategory, getCity } from "@/api/common";
import passWord from "@/components/password";
import jingqing from "@/assets/jingqing.jpg"
let moment = require('moment');
export default {
  name: 'Main',
  components: { passWord },
  data() {
    return {
      searchForm: {
        date: '',
        endTime: '', 
        startTime: '', 
        goods: '',
        name: '',
        status: '1',
        supplierOrgId: '',
        category: '',
        city: [],
      },
      props: { multiple: true, checkStrictly: true },
      page: 1,
      total: 0,
      pageSize: 18,
      passVisible: false,
      uploadflag: false,
      fileList: [],
      orderData: [],
      srcList: [],
      tableData: [],
      categoryArr: [],
      provinceArr: [],
      orderflag: false,
      jingqing: jingqing,
      downflag: false,
      logining: false,
      modyflag: false,
      barImagesArr: [],
      tuanTip: '本周',
      formAdd: {
        goodsName: '',
        consigneeName: '',
        consigneePhone: '',
        consigneeAddress: ''
      }
    }
  },
  methods: {
    choucang(row){
      this.downflag = true
      var datas = {
        id: row.id
      }
      this.barImagesArr = row.barImages || []
      this.barImagesArr.map((item)=>{
        this.srcList.push(item.resourceCode)
      })
      favoriteTuan(datas).then(res => {
        console.log(res)
      })
    },
    getCategoryAll(){
      var that = this
      globalCategory().then(res => {
        console.log(res);
        if(res.code ===200) {
          that.categoryArr = res.data
          console.log(that.classArr)
        }else{
          that.$message.error(res.message)
        }
      })
    },
   
    getCityList(){
      var that = this
      var objs = {}
      getCity(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.provinceArr = res.data.districts[0].districts || []
          that.provinceArr.map((item, key) => {
            item.label = item.name
            item.value = item.name
            item.children = item.districts
            item.children.map((dist)=>{
              dist.value = dist.name
              dist.label = dist.name
              // dist.children = []
            })
          })

        }else{
          that.$message.error(res.message)
        }
      })
    },
    checkProvince(val){
      console.log(val)
      var that = this
      that.provinceArr.map((item, key) => {
        if(item.name == val){
          that.cityArr = item.districts
          that.goodArr.city = '';
        }
      })
    },
    cancalBtn(ids){
      var datas = {
        id: ids
      }
      this.$confirm('您确定要取消分销该团?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cancelFavorite(datas).then(res => {
           that.$message({
            message: '操作成功',
            type: 'success'
          });
        })
        that.getTopSearch()
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    getTopSearch(){
      var that = this
      var datas = that.searchForm;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      var cityArr =[]
      that.searchForm.city.map((item)=>{
        var objsss = {
          province: item[0],
          city: item[1],
          district: item[2]
        }
        cityArr.push(objsss);
      })
      datas.cities = cityArr
      datas.start = that.searchForm.date ? moment(that.searchForm.date[0]).format('YYYY-MM-DD 00:00:00') : '';
      datas.end = that.searchForm.date ? moment(that.searchForm.date[1]).format('YYYY-MM-DD 23:59:59') : '';
      dashboardTuan(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          nodataArr.map((item)=>{
            item.beginTimes = moment(item.startTime).format('YYYY-MM-DD 00:00:00')
            item.endTimes = moment(item.endTime).format('YYYY-MM-DD 23:59:59')
            item.cityArr = that.uniqueFun(item.cities, 'city')
          })
          that.tableData = nodataArr
          console.log(that.tableData)
          that.total = res.data.totalRows
        }else{
          that.$message.error(res.message)
        }
      })
    },
    uploadFun(n){
      console.log(n)
      this.uploadflag = true
      this.uploadId = n
    },
    jinduFun(e){
      console.log(e)
    },
    chakantuan(row){
      this.$router.push({
        path: "/tuandetail?id=" + row.id
      })
    },
    ooClose(){
      this.orderflag = false
    },
    modyClose(){
      this.modyflag = false
    },
    UploadImage(param){
      var that = this
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('id', '1414881753294700545')
      importOrder(formData).then(res => {
      	console.log('上传图片成功')
        param.onSuccess() 
        if(res.code === 200){
          that.orderflag = true
          this.uploadflag = false
          that.orderData = res.data
          that.total = res.data.length
        }else{
          that.$message.error(res.message)
        }

      }).catch(response => {
        console.log('图片上传失败')
        param.onError()
      })
    },
    handleClose(){
      this.uploadflag = false
    },
    handleSubmit(){
      var that = this
      var objs = {}
      objs.orders = that.orderData
      batchAdd(objs).then(res => {
          this.$router.push({
            path: "/dingdan"
          })
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
       //根据对象某个属性去重
    uniqueFun(arr, val){
      const res = new Map()
      return arr.filter(item =>!res.has(item[val]) && res.set(item[val], 1))
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`)
    },
    addfun(){
      this.$router.push({
        path: "/sendtuan/detail"
      })
    }, 
    modifyFun(row){
      this.modyflag = true
      this.formAdd = row
    },
    downClose(){
      this.downflag = false
    },
    chongzhi(){
      this.searchForm = {
         date: '',
        endTime: '', 
        startTime: '', 
        goods: '',
        name: '',
        status: '1',
        supplierOrgId: '',
        category: '',
        city: [],
      }
      this.getTopSearch()
    },
    downloadFun(n){
      let link = document.createElement('a')
      link.href = n
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  },
  mounted() {
    var that = this
    that.getTopSearch()
    that.getCategoryAll()
    that.getCityList()
  }
}
</script>


<style scoped>
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.orderHeight{
  height: 500px;
  overflow-x: hidden;
  overflow-y:scroll;
  -webkit-overflow-scrolling: touch;
}
.cardList{
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}
@media screen and (max-width: 1338px){
  .cardList{
    width: 844px;
  }
}
@media screen and (min-width: 1338px){
  .cardList{
    width: 1266px;
  }
}
@media screen and (min-width: 1920px){
  .cardList{
    width: 1688px;
  }
}
.cardBox{
  float: left;
  width: 420px;
  overflow: hidden;
}
.cardItem{
  margin: 5px;
  display: block;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 10px #eee;
  border-radius: 4px;
}
.biaoti{
  font-size: 14px;
  color: #333;
  height: 50px;
  overflow: hidden;
  line-height: 22px;
  display: block;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.biaoti span{
  background-color: #ff6600;
  color: #fff;
  font-size: 12px;
  padding: 2px 5px;
  margin-right: 5px;
}
.biaoti bdo{
  /* background-color: #fe4729; */
  padding: 0 6px;
  margin-right: 4px;
  display: inline-block;
  color: #fe4729;
  border-radius:4px;
  font-size: 13px;
  border: 1px solid #fe4729;
  margin-bottom: 2px;
}
.pricebox{
  display: block;
  line-height: 30px;
  color: #fe4729;
  font-weight: bold;
  font-size: 20px;
  height: 30px;
  padding: 5px 0;
}
.pricebox em{
  font-style: normal;
  font-size: 12px;
}
.pricebox span{
  float: right;
  font-size: 12px;
  color: #999;
  font-weight: normal;
}
.imglist{
  display: block;
  overflow: hidden;
  padding-bottom: 10px;
  height: 120px;
}
.imglist ol{
  float:left;
  width: 33.33%;
  height: 200px;
  margin: 0;
  padding: 0;
}
.clickNumIndex{
  background-color: #ebfef4;
  padding: 10px 10px 10px 0;
  display: flex;
  overflow: hidden;
}
.itemindex{
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: #999;
  margin: 0;
  padding: 0;
}
.titlebox{
  width: 360px;
  line-height: 22px;
}
.itemStrong{
  font-size: 16px;
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color:#07C160;
  height: 22px;
  margin: 0;
  padding: 0;
}
.imgsize{
  width: 110px;
  height: 110px;
  display: block;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 auto;
}
.caozuoBtn{
  clear: both;
  padding: 10px 0 0 0;
  overflow: hidden;
  font-size: 12px;
  height: 40px;
}
.caozuoBtn bdo{
  color:#07c160;
  line-height:30px;
  padding: 4px 12px;
  border-radius: 20px;
  background: rgba(7,193,96,.08);
}
.caozuoBtn bdo.huise{
  background: rgba(21,21,22,.08);
  color: #9c9c9c;
}
.upload-demo{
  display: block;
  margin: 0 auto;
  padding: 10px 0 50px;
  text-align: center;
}
.caozuoBtn span{
  float: right;
  margin-left: 10px;
  /* border: 1px solid #999;
  padding: 2px 10px;
  line-height:22px;
  border-radius: 4px;
  color:#282828;
  margin-left: 10px;
  cursor: pointer; */
}
.fenxiaoBox{
  display: block;
  overflow: hidden;
}
.fenxiaoBox p{
  background-color: #eee;
  line-height: 30px;
  margin-bottom: 10px;
}
.fenxiaoBox ol{
  float: left;
  width: 120px;
  text-align:center;
  border: 1px solid #eee;
  padding: 5px;
  margin: 0 10px;
}
.fenimg{
  width: 120px;
  height: 120px;
  display: block;
  margin: 0 auto;
}
</style>

